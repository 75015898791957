<template>
  <div class="service-requests">
    <!-- Service Request Card 1 -->
    <div class="service-request-card">
      <div class="request-info">
          <h3>{{ $t("UnitOwnerInformation") }}</h3>
        <p><strong>{{ $t("FullName") }}:</strong> {{ CustomersService.ownerName }}</p>
        <p><strong>{{ $t("PhoneNumber") }}:</strong> {{ CustomersService.phoneNumber }}</p>
      </div>

    </div>

    <!-- Service Request Card 2 -->
   <div class="service-request-card">
      <div class="unit-info">
        <h3>{{ $t("UnitDetails") }}</h3>
        <p><strong>{{ $t("UnitNumber") }}:</strong> {{ CustomersService.unitDetails.unitNumber }}</p>
        <p><strong>{{ $t("buildingNumber") }}:</strong> {{ CustomersService.unitDetails.buildingName }}</p>
         <p><strong>{{ $t("Round") }}:</strong> {{ CustomersService.unitDetails.levelName }}</p>
        <p><strong>{{ $t("UnitModel") }}:</strong> {{ CustomersService.unitDetails.modelName }}</p>

      </div>
    </div>

    <!-- Service Request Card 3 -->
    <div class="service-request-card">
      <div class="unit-info">
        <h3>{{ $t("ServiceDetails") }}</h3>
      <p><strong>{{ $t("Type") }}:</strong> {{ CustomersService.serviceTypeName }}</p>
      <p><strong>{{ $t("Details") }}:</strong> {{ CustomersService.details }}</p>
      <p><strong>{{ $t("RequestDate") }}:</strong> {{ formatDate(CustomersService.createdDate) }}</p>
      </div>
    </div>
    <div class="status-buttons">
    <button v-if="CustomersService.statusID === null || CustomersService.statusID === 1 && !processing && !started"
            @click.stop="startService(CustomersService)"
            class="start-button">
      {{ $t("StartService") }}
    </button>
    <button v-if=" CustomersService.statusID ===  1  && !processing && started"
            @click="markProcessing(CustomersService)"
            class="processing-button">
      {{ $t("MarkAsProcessing") }}
    </button>
    <button v-if="CustomersService.statusID === 2 "
            @click="finishService(CustomersService)"
            class="finish-button">
      {{ $t("FinishService") }}
    </button>
    <button v-if="CustomersService.statusID === 3" disabled>
      {{ $t("ServiceFinished") }}
    </button>
  </div>
  </div>

  <!-- Status Buttons -->

</template>

<script>
import moduleCustomersService from "@/store/CustomersService/moduleCustomersService.js";

export default {
  data() {
    return {
      started: false,
      processing: false,
      // CustomersService: {
      //   ownerName:"",
      //   phoneNumber:"",
      //   unitNumber: "",
      //   uintModelName: "",
      // },
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    CustomersService(){
      return this.$store.state.CustomersServiceList.CustomersServices;
    }
  },
  methods: {
    formatDateHours(dateString) {
      const date = new Date(dateString);
      return date.toLocaleTimeString(); // Adjust format as needed
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString(); // Adjust format as needed
    },
    startService(data) {
      this.started = true;
      var Id = data.id;
      this.$store
        .dispatch("CustomersServiceList/started", Id)
        .then(() => {
          const ID = this.$route.params.ID;
          this.$store.dispatch("CustomersServiceList/GetItemByID", ID)

        });
      console.log('Service started for unit:', this.unit.number);
    },
    markProcessing(data) {
      this.processing = true;
      var Id =  data.id;
      this.$store
        .dispatch("CustomersServiceList/processing", Id)
        .then(() => {

          this.cancel();

        });
    },
    finishService(data) {
      this.started = false;
      this.processing = false;
      var Id =  data.id;
      this.$store
        .dispatch("CustomersServiceList/finish", Id)
        .then(() => {
          debugger;

          this.cancel();


        });
    },
    cancel() {
      this.$router.go(-1);
    },
  },
  created() {
    if (!moduleCustomersService.isRegistered) {
      this.$store.registerModule( "CustomersServiceList",moduleCustomersService);
      moduleCustomersService.isRegistered = true;
    }
    debugger;
    const ID = this.$route.params.ID;
    debugger;
    if (ID != undefined && ID != 0) {
      debugger;
      this.$store.dispatch("CustomersServiceList/GetItemByID", ID)
      // .then((res) => {
      //   debugger;
      //   this.CustomersService = res.data.data;
      //   console.log(this.CustomersService);
      // });
    } else if (ID == undefined) {
      ID == 0;
    }
  //  this.fetchService();
  },
  mounted() {
    this.isMounted = true;
  },
}
</script>

<style scoped>
.service-requests {
  display: flex;
  flex-wrap: wrap; /* Allow the cards to wrap */
  justify-content: space-between;
}

.service-request-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: calc(33.33% - 20px); /* Adjust width of each card */
  margin-bottom: 20px;
}

.request-info h3,
.unit-info h3,
.service-details h3 {
  color: #007bff;
  margin-bottom: 15px;
}
p {
  margin-bottom: 10px;
}

.status-buttons {
  width: 100%; /* Ensure the buttons span the full width */
  display: flex;
  justify-content: center; /* Center align the buttons */
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #e9ecef;
}

.start-button {
  background-color: #28a745;
  color: #fff;
}

.processing-button {
  background-color: #ffc107;
  color: #212529;
}

.finish-button {
  background-color: #007bff;
  color: #fff;
}
</style>
