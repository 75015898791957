export default {
  ADD_ITEM(state, item) {
    debugger;
    state.CustomersServices.unshift(item);
  },
  SET_AdsManagements(state, CustomersServices) {
    state.CustomersServices = CustomersServices;
  },
  UPDATE_AdsManagement(state, CustomersService) {
    const CustomersServiceIndex = state.CustomersServices.findIndex(p => p.ID == CustomersService.ID);
    if (CustomersServiceIndex != -1) {
      Object.assign(state.CustomersServices[CustomersServiceIndex], CustomersService);
    }
  },

  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.CustomersServices.findIndex(p => p.ID == itemId);
    state.CustomersServices.splice(ItemIndex, 1);
  },


};
