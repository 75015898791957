import axios from "@/axios.js";

export default {
  addItem({ commit }, item) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      debugger;
      axios
        .post("/API/ServiceRequest/AddServiceRequest", item,{headers:Token})
        .then(response => {
          debugger;
          resolve(response);
          commit(
            "ADD_ITEM",
            Object.assign(item, { ID: response.data.data.ID })
          );
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchDataListItems({ commit }) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
        .get("API/ServiceRequest/GetAllServiceRequest",{headers:Token})
        .then(response => {
          debugger;
          resolve(response);
          commit("SET_AdsManagements", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  SearchCustomersService({ commit }, search) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
        .post("API/ServiceRequest/SearchUnitOwner", search,{headers:Token})
        .then(response => {
          debugger;
          resolve(response);
          commit("SET_AdsManagements", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateItem(context, item) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
        .post("API/ServiceRequest/UpdateServiceRequest", item,{headers:Token})
        .then(response => {
          resolve(response);
         // commit("UPDATE_UnitOwner", item);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetItemByID({ commit }, itemid) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
      .get("API/ServiceRequest/GetServiceRequestById?Id=" + itemid,{headers:Token})
        .then(response => {
          debugger;
          resolve(response);
          commit('SET_AdsManagements', response.data.data)

        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetNewRequestsCount() {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      debugger;
      axios
      .get("API/ServiceRequest/GetNewRequestsCount",{headers:Token})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  DeleteCustomersService({ commit }, item) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios
        .delete("API/UnitOwner/GetAllServiceRequest?Id=" + item.id,{headers:Token})
        .then(response => {
          resolve(response);
          commit("REMOVE_ITEM", item.ID);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
   finish({ commit }, Id) {
    try {
      // Make API call to update service status to finished
      const response =  axios.put("API/ServiceRequest/FinishRequest?Id="+Id)
      // Assuming the response contains updated service data
      commit('SET_AdsManagements', response.data);
    } catch (error) {
      console.error('Error finishing service:', error);
      throw error;
    }
  },
   processing({ commit }, Id) {
    try {
      // Make API call to update service status to processing
      const response =  axios.put("API/ServiceRequest/ProcessingRequest?Id="+Id)

      // Assuming the response contains updated service data
      commit('SET_AdsManagements', response.data);
    } catch (error) {
      console.error('Error processing service:', error);
      throw error;
    }
  },
  started({ commit }, Id) {
    try {
      // Make API call to update service status to processing
      const response =  axios.put("API/ServiceRequest/StartingRequest?Id="+Id)

      // Assuming the response contains updated service data
      commit('SET_AdsManagements', response.data);
    } catch (error) {
      console.error('Error processing service:', error);
      throw error;
    }
  },
};
