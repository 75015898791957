
import state from "./CustomersServicetState.js"
import mutations from "./CustomersServiceMutations.js"
import getters from "./CustomersServiceGetters.js"
import actions from './CustomersServiceActions.js';
export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
